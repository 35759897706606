@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons+Outlined);
@import url(https://fonts.googleapis.com/css?family=Mulish:300,400,500,600,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Muli", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* Temporary hack while I work out how roboto is being added*/
label, input {
  font-family: 'Mulish', Serif !important; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga'; }

.material-icons.material-icons-outlined {
  font-family: 'Material Icons Outlined'; }

span .material-icons.material-icons-outlined {
  margin-top: -3px; }

html, body {
  height: 100%;
  overflow-y: hidden;
  font-family: Mulish; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

#codeEl {
  height: calc(100vh - 160px); }

.CodeMirror {
  height: 100% !important; }

.js-editor {
  height: 100%;
  width: 100%;
  position: absolute; }

.CodeMirror-line span {
  font-family: hack;
  font-size: 11px; }

.CodeMirror-linenumber {
  font-family: monospace;
  font-size: 13px;
  padding-top: 5px; }

.modify-modal-container {
  width: 100%;
  position: relative; }

.DraftEditor-editorContainer {
  max-height: 200px;
  overflow: auto;
  border: 1px solid #eee;
  padding: 12px;
  margin-bottom: 16px;
  background: #ddd !important; }

.rdw-colorpicker-wrapper {
  position: static; }

.rdw-editor-toolbar {
  position: relative; }

.rdw-colorpicker-modal {
  left: auto !important;
  right: 20px; }

.w3-boxmodel {
  font-size: 1.1em;
  width: 410px;
  height: 380px;
  position: relative;
  cursor: pointer;
  font-size: 14px; }

.w3-boxmodel .boxmodel-editor {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 390px;
  height: 340px;
  z-index: 1;
  padding: 20px;
  cursor: default; }

.w3-boxmodel .margin {
  background: #f1f1f1;
  padding: 45px;
  position: relative;
  border: 2px dashed #bbb;
  transition: all .25s ease-in-out; }

.ace_editor, .ace_editor div {
  font-family: hack, monospace; }

.w3-boxmodel .margin .label {
  font-size: 0.8rem; }

.w3-boxmodel .margin .ml, .w3-boxmodel .margin .pl, .w3-boxmodel .margin .bl {
  position: absolute;
  left: 6px;
  top: 50%;
  margin-top: -10px; }

.w3-boxmodel .margin .mt, .w3-boxmodel .margin .pt, .w3-boxmodel .margin .bt {
  position: absolute;
  top: 10px;
  left: 50%;
  margin-left: -10px; }

.w3-boxmodel .margin .mr, .w3-boxmodel .margin .pr, .w3-boxmodel .margin .br {
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -10px; }

.w3-boxmodel .margin .mb, .w3-boxmodel .margin .pb, .w3-boxmodel .margin .bb {
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin-left: -10px; }

.w3-boxmodel .margin:before {
  content: "margin";
  position: absolute;
  left: 0;
  top: 3%;
  width: 100%;
  padding-left: 12px;
  text-align: left; }

.w3-boxmodel .border {
  padding: 45px;
  position: relative;
  background: #98D4D5; }

.w3-boxmodel .border:before {
  content: "border";
  color: #333;
  position: absolute;
  left: 0;
  top: 5%;
  width: 100%;
  padding-left: 12px;
  text-align: left; }

.w3-boxmodel .padding {
  color: black;
  padding: 45px;
  position: relative;
  background: #f1f1f1; }

.w3-boxmodel .padding:before {
  content: "padding";
  width: 100%;
  position: absolute;
  left: 2%;
  top: 7%;
  padding-left: 12px;
  text-align: left; }

.w3-boxmodel .content {
  padding: 20px;
  position: relative;
  background: white;
  border: 2px dashed #bbb; }

.w3-boxmodel .content:before {
  content: "CONTENT";
  display: block;
  text-align: center;
  line-height: 3.5;
  width: 100%; }

div.dropdown__control {
  border-radius: 1px;
  background: #ffffff;
  color: #13364F;
  font-size: 14px;
  border: 1px solid #D9DFE3;
  border-radius: 4px;
  min-height: 32px !important; }

.dropdown__value-container {
  background: transparent;
  height: 32px; }

.dropdown__dropdown-indicator {
  padding: 4px 2px !important; }

.dropdown__dropdown-indicator svg {
  color: #13364F !important;
  opacity: 0.4; }

span.dropdown__indicator-separator {
  color: #13364F !important;
  background: transparent;
  margin-top: 0;
  margin-bottom: 0; }

.dropdown__indicators {
  background-color: transparent;
  border: none !important; }

.dropdown__menu {
  z-index: 99999; }

.dropdown--is-disabled {
  opacity: 0.5; }

.dropdown__control.dropdown__control--is-disabled {
  background: #bdbdbd; }

.revui_input_text {
  background: #F1F1F1;
  display: block;
  padding: 8px;
  padding: 8px 16px 8px 0px !important; }

input {
  border: 0;
  margin: 0;
  border-radius: 1px;
  background-color: inherit;
  color: #13364F;
  width: 100%;
  font-size: 14px;
  height: 32px; }

.MuiCard-root {
  overflow: visible; }

.grid-editor .MuiFormLabel-root,
.grid-editor .MuiInputBase-input {
  min-width: 50px; }

.grid-editor .MuiGrid-container {
  flex-wrap: inherit; }

.MuiInputLabel-shrink {
  -webkit-transform: translate(0, -3px) scale(1) !important;
          transform: translate(0, -3px) scale(1) !important;
  -webkit-transform-origin: top left;
          transform-origin: top left; }

.MuiTypography-root.MuiFormControlLabel-label {
  color: #13364F;
  font-size: 14px;
  font-weight: 800; }

#spacingContainer .spacing-radio {
  margin-top: 20px;
  margin-right: 10px;
  float: left;
  border: 1px solid #efefef;
  cursor: pointer;
  background-color: #f8f8f8;
  width: 100px;
  height: 100px;
  padding: 10px; }
  #spacingContainer .spacing-radio h3 {
    font-size: 0.8rem;
    color: #13364F;
    margin: 0 0 20px; }
  #spacingContainer .spacing-radio .spacing-input {
    display: none; }
  #spacingContainer .spacing-radio .gfx-block {
    width: 100%;
    height: 15px;
    display: block;
    background-color: #98D4D5; }
  #spacingContainer .spacing-radio.selected {
    border: 1px solid #98D4D5;
    background-color: #98D4D5; }
    #spacingContainer .spacing-radio.selected h3 {
      color: #E9FBFB; }
    #spacingContainer .spacing-radio.selected .gfx-block {
      background-color: #D1ECEC; }
  #spacingContainer .spacing-radio.compact-graphic .gfx-block {
    margin-bottom: 3px; }
  #spacingContainer .spacing-radio.normal-graphic .gfx-block {
    margin-bottom: 6px; }
  #spacingContainer .spacing-radio.super-graphic .gfx-block {
    margin-bottom: 10px; }

#HeaderLayoutContainer .layout-radio {
  margin-top: 20px;
  margin-right: 10px;
  float: left;
  border: 2px dashed #B9B9B9;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  marign-bottom: 10px;
  background-color: #F1F1F1; }
  #HeaderLayoutContainer .layout-radio .logo {
    font-size: 0.8rem;
    color: white;
    margin: 0 0 20px;
    padding: 10px;
    width: 15%;
    background: #98D4D5;
    display: block;
    font-weight: bold; }
  #HeaderLayoutContainer .layout-radio .layout-input {
    display: none; }
  #HeaderLayoutContainer .layout-radio .gfx-block {
    width: 50%;
    height: 15px;
    display: block;
    background-color: rgba(185, 185, 185, 0.27);
    margin-bottom: 7px; }
  #HeaderLayoutContainer .layout-radio .gfx-block-long {
    width: 70%; }
  #HeaderLayoutContainer .layout-radio.center-graphic .logo {
    margin: 0 auto 10px; }
  #HeaderLayoutContainer .layout-radio.center-graphic .gfx-block {
    margin: 0 auto 6px; }
  #HeaderLayoutContainer .layout-radio.left-graphic .logo {
    float: left;
    margin-bottom: 0px; }
  #HeaderLayoutContainer .layout-radio.left-graphic .gfx-block {
    float: right; }
  #HeaderLayoutContainer .layout-radio.right-graphic .logo {
    float: right;
    margin-bottom: 0px; }
  #HeaderLayoutContainer .layout-radio.right-graphic .gfx-block {
    float: left; }
  #HeaderLayoutContainer .layout-radio.selected {
    border: 2px solid #98D4D5;
    background-color: #98D4D5; }
    #HeaderLayoutContainer .layout-radio.selected .logo {
      color: #98D4D5;
      background: #D1ECEC; }
    #HeaderLayoutContainer .layout-radio.selected .gfx-block {
      background-color: #D1ECEC; }

#FeatureLayoutContainer .layout-radio {
  margin-top: 20px;
  margin-right: 10px;
  float: left;
  border: 2px dashed #B9B9B9;
  cursor: pointer;
  width: 100px;
  height: 100px;
  padding: 10px;
  marign-bottom: 10px;
  background-color: #F1F1F1; }
  #FeatureLayoutContainer .layout-radio .logo {
    font-size: 0.8rem;
    color: white;
    margin: 0 auto 10px;
    width: 40px;
    padding: 10px 20px 10px;
    background: #98D4D5;
    display: block;
    font-weight: bold;
    text-align: center; }
  #FeatureLayoutContainer .layout-radio .layout-input {
    display: none; }
  #FeatureLayoutContainer .layout-radio .gfx-block {
    width: 50%;
    height: 15px;
    display: block;
    background-color: rgba(185, 185, 185, 0.27);
    margin: 0 auto 10px;
    text-align: center;
    line-height: 14px;
    padding: 2px;
    font-size: 12px;
    color: #5da4a5; }
  #FeatureLayoutContainer .layout-radio .gfx-btn {
    border-radius: 2px;
    height: 15px;
    display: block;
    width: 60px;
    margin: 0 auto 5px;
    text-align: center;
    line-height: 13px;
    padding: 3px 5px;
    font-size: 11px;
    background-color: #5da4a5;
    color: white;
    margin-top: 10px; }
  #FeatureLayoutContainer .layout-radio .gfx-text {
    font-size: 11px;
    text-align: center;
    margin-top: 10px;
    color: #5da4a5; }
  #FeatureLayoutContainer .layout-radio .gfx-block-long {
    width: 70%; }
  #FeatureLayoutContainer .layout-radio.layout-three-graphic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: repeating-linear-gradient(45deg, #F1F1F1, #F1F1F1 10px, #f2fafa 10px, #f2fafa 20px); }
  #FeatureLayoutContainer .layout-radio.selected {
    border: 2px solid #98D4D5;
    background: #98D4D5; }
    #FeatureLayoutContainer .layout-radio.selected .gfx-text {
      color: white; }
    #FeatureLayoutContainer .layout-radio.selected .logo {
      color: #98D4D5;
      background: #D1ECEC; }
    #FeatureLayoutContainer .layout-radio.selected .gfx-block {
      background-color: #D1ECEC; }

#RoundelContainer .layout-radio {
  position: relative;
  margin-bottom: 20px;
  margin-right: 10px;
  float: left;
  border: 2px dashed #B9B9B9;
  cursor: pointer;
  width: 45px;
  height: 45px;
  padding: 10px;
  marign-bottom: 10px;
  background-color: #F1F1F1; }
  #RoundelContainer .layout-radio .layout-input {
    display: none; }
  #RoundelContainer .layout-radio .gfx-block-long {
    width: 70%; }
  #RoundelContainer .layout-radio .gfx-circle {
    width: 45px;
    height: 45px;
    background: #98D4D5;
    border-radius: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  #RoundelContainer .layout-radio .gfx-square {
    width: 45px;
    height: 45px;
    background: #98D4D5;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  #RoundelContainer .layout-radio .gfx-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 45px 0 0 45px;
    border-color: transparent transparent transparent #98D4D5;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  #RoundelContainer .layout-radio .gfx-quarter-circle {
    width: 45px;
    height: 45px;
    background: #98D4D5;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0px 60px 0px 0px; }
  #RoundelContainer .layout-radio .gfx-banner {
    width: 15px;
    height: 45px;
    background: #98D4D5;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  #RoundelContainer .layout-radio .gfx-layout {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #98D4D5; }
  #RoundelContainer .layout-radio .shape-quarter-circle {
    border-radius: 0px 30px 0px 0px; }
  #RoundelContainer .layout-radio .shape-circle.gfx-layout {
    border-radius: 30px; }
  #RoundelContainer .layout-radio .shape-banner.gfx-layout {
    width: 10px; }
  #RoundelContainer .layout-radio .shape-triangle.gfx-layout {
    width: 0;
    height: 0;
    border-style: solid;
    background: none; }
    #RoundelContainer .layout-radio .shape-triangle.gfx-layout.gfx-bottom-left {
      border-color: transparent transparent transparent #98D4D5;
      border-width: 30px 0 0 30px; }
    #RoundelContainer .layout-radio .shape-triangle.gfx-layout.gfx-bottom-right {
      border-color: transparent transparent #98D4D5 transparent;
      border-width: 0 0 30px 30px; }
    #RoundelContainer .layout-radio .shape-triangle.gfx-layout.gfx-top-left {
      border-color: #98D4D5 transparent transparent transparent;
      border-width: 30px 30px 0 0; }
    #RoundelContainer .layout-radio .shape-triangle.gfx-layout.gfx-top-right {
      border-color: transparent #98D4D5 transparent transparent;
      border-width: 0 30px 30px 0; }
  #RoundelContainer .layout-radio .gfx-layout.shape-quarter-circle.gfx-bottom-left {
    border-radius: 0 100% 0px 0px; }
  #RoundelContainer .layout-radio .gfx-layout.shape-quarter-circle.gfx-bottom-right {
    border-radius: 100% 0px 0px 0px; }
  #RoundelContainer .layout-radio .gfx-layout.shape-quarter-circle.gfx-top-left {
    border-radius: 0px 0px 100% 0px; }
  #RoundelContainer .layout-radio .gfx-layout.shape-quarter-circle.gfx-top-right {
    border-radius: 0px 0px  0px 100%; }
  #RoundelContainer .layout-radio .gfx-top-left {
    top: 5px;
    left: 5px; }
  #RoundelContainer .layout-radio .gfx-top-right {
    top: 5px;
    right: 5px; }
  #RoundelContainer .layout-radio .gfx-bottom-left {
    bottom: 5px;
    left: 5px; }
  #RoundelContainer .layout-radio .gfx-bottom-right {
    bottom: 5px;
    right: 5px; }
  #RoundelContainer .layout-radio.selected {
    border: 2px solid #98D4D5;
    background: #98D4D5; }
    #RoundelContainer .layout-radio.selected .gfx-shape, #RoundelContainer .layout-radio.selected .gfx-layout {
      background: white; }
    #RoundelContainer .layout-radio.selected .gfx-triangle {
      border-color: transparent transparent transparent white; }
    #RoundelContainer .layout-radio.selected .gfx-quarter-circle {
      background: white; }
    #RoundelContainer .layout-radio.selected .gfx-layout.shape-triangle {
      background: none; }
      #RoundelContainer .layout-radio.selected .gfx-layout.shape-triangle.gfx-bottom-left {
        border-color: transparent transparent transparent white; }
      #RoundelContainer .layout-radio.selected .gfx-layout.shape-triangle.gfx-bottom-right {
        border-color: transparent transparent white transparent; }
      #RoundelContainer .layout-radio.selected .gfx-layout.shape-triangle.gfx-top-left {
        border-color: white transparent transparent transparent; }
      #RoundelContainer .layout-radio.selected .gfx-layout.shape-triangle.gfx-top-right {
        border-color: transparent white transparent transparent; }

@-webkit-keyframes fadeScale {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeScale {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.field-label {
  opacity: 0.7; }

.label-with-tooltip {
  display: inline-block;
  margin: 0px;
  color: #13364F;
  margin-right: 8px;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 13px; }

.tooltip-contain {
  display: block;
  flex-direction: row !important;
  position: relative;
  margin-bottom: 0px !important; }

.i-tooltip {
  display: inline-block;
  border-radius: 13px;
  width: 14px;
  height: 14px;
  border: 1px solid #8899A5;
  font-size: 12px;
  line-height: 11px;
  text-align: center;
  font-weight: 900;
  color: #8899A5;
  cursor: pointer; }
  .i-tooltip:before {
    content: attr(data-text);
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    bottom: 0px;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    right: 0px;
    margin-left: 8px;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background: #425E72;
    color: #fff;
    text-align: center;
    display: none;
    opacity: 0;
    z-index: 999; }
  .i-tooltip:hover:before {
    display: block;
    -webkit-animation: fadeScale 0.3s forwards;
            animation: fadeScale 0.3s forwards; }

.MuiCheckbox-root .MuiSvgIcon-root {
  border-color: #D9DFE3; }

.schema-editor {
  max-width: 620px;
  padding-top: 32px !important; }
  .schema-editor .MuiGrid-root .MuiCardContent-root {
    padding-left: 0px;
    position: relative; }
  .schema-editor .MuiSwitch-root {
    margin-left: 40px; }
  .schema-editor div[class^="SchemaAttachedEditor-menuItem"] {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px; }
  .schema-editor .current-menu__title {
    font-size: 24px !important;
    margin-top: 32px; }
  .schema-editor .title-group {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #DFF1F1; }
  .schema-editor .MuiTypography-h5, .schema-editor .title-group__title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    margin-bottom: 32px; }
  .schema-editor .collapse-toggle .plus {
    display: none; }
  .schema-editor .collapse-toggle .minus {
    display: inline; }
  .schema-editor .collapse-toggle.collapsed .plus {
    display: inline; }
  .schema-editor .collapse-toggle.collapsed .minus {
    display: none; }

.accordion, .accordion-item, .accordion-header, .accordion-button {
  background: transparent !important; }

.responsive-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top:23px;
    position: absolute;
    right: 0;
}

.responsive-controls ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

}

.responsive-controls ul li {
    float: left; margin-right: 4px;
    cursor: pointer;
    background: #FFF;

}

.responsive-controls ul li:HOVER {
    background: #333;
    color: #FFF;
}

.responsive-controls ul li:HOVER i {
    color: #FFF;
}

.responsive-controls ul li.selected {
    background: #EA4E8F;
}

.responsive-controls ul li.selected i {
    color: #FFF;
}

.responsive-controls ul li i {
    font-size: 20px;
color: #333;
    padding: 10px;
}

.responsive-controls ul li i.rotate90 {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);

}
.primaryIcon {
    font-size: 44px;
    text-align: center;
    color: #EA4E8F
}

.chrome-picker {
    z-index: 9999999;
}
.primaryIcon {
    font-size: 44px;
    text-align: center;
    color: #EA4E8F
}
.tui-image-editor-header-logo { display: none;}
.tui-image-editor-header-buttons {display:none;}
