.responsive-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top:23px;
    position: absolute;
    right: 0;
}

.responsive-controls ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

}

.responsive-controls ul li {
    float: left; margin-right: 4px;
    cursor: pointer;
    background: #FFF;

}

.responsive-controls ul li:HOVER {
    background: #333;
    color: #FFF;
}

.responsive-controls ul li:HOVER i {
    color: #FFF;
}

.responsive-controls ul li.selected {
    background: #EA4E8F;
}

.responsive-controls ul li.selected i {
    color: #FFF;
}

.responsive-controls ul li i {
    font-size: 20px;
color: #333;
    padding: 10px;
}

.responsive-controls ul li i.rotate90 {
    transform: rotate(90deg);

}