@import "assets/styles/font";

$darkColor: #13364F;
$primaryColor:#98D4D5;
$primaryColorLight:#D1ECEC;
$primaryColorLighter:#f2fafa;
$primaryColorDark:#5da4a5;
$secondaryColor:#98D4D5;
$mediumGrey: #B9B9B9;
$lightGrey:#F1F1F1;
$medLightGrey: rgba(185,185,185,0.27);
$darkestGrey: #000000;

html, body {
  height: 100%;
  overflow-y: hidden;
  font-family: Mulish;

}

.App {
  text-align: center;
} 

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#codeEl {height: calc(100vh - 160px);}
.CodeMirror {height:100%!important; }

.js-editor {
  height:100%;
  width:100%;
  position: absolute;
}
.CodeMirror-line span {
  font-family: hack;
  font-size: 11px;
}
.CodeMirror-linenumber {
  font-family: monospace;
  font-size: 13px;
  padding-top: 5px;
}


.modify-modal-container { width: 100%; position:relative;}


.DraftEditor-editorContainer {
  max-height: 200px;
  overflow: auto;
  border: 1px solid #eee;
  padding: 12px;
  margin-bottom: 16px;
  background:#ddd !important;
}
.rdw-colorpicker-wrapper{
  position:static;
}
.rdw-editor-toolbar{
  position:relative;
}
.rdw-colorpicker-modal{
  left:auto !important;
  right:20px;
} 


  
  .w3-boxmodel {
    font-size: 1.1em;
    width: 410px;
    height: 380px;
    position: relative;
    cursor: pointer;
    font-size:14px;
  }
  
  .w3-boxmodel .boxmodel-editor {
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0,0,0, 0.4);
      width: 390px;
      height: 340px;
      z-index: 1;
      padding: 20px;
      cursor: default;
  }
  
  .w3-boxmodel .margin {
    background: #f1f1f1;
    padding: 45px;
    position: relative;
    border: 2px dashed #bbb;
    transition: all .25s ease-in-out;
  }


  .ace_editor, .ace_editor div{
    font-family:hack, monospace;
  }

  .w3-boxmodel .margin .label{
    font-size: 0.8rem;
  }

  .w3-boxmodel .margin .ml, .w3-boxmodel .margin .pl, .w3-boxmodel .margin .bl{ 
    position: absolute; left: 6px; top: 50%; margin-top:-10px;
  }
  .w3-boxmodel .margin .mt, .w3-boxmodel .margin .pt, .w3-boxmodel .margin .bt{ 
    position: absolute; top: 10px; left: 50%;margin-left:-10px; 
  }
  .w3-boxmodel .margin .mr, .w3-boxmodel .margin .pr, .w3-boxmodel .margin .br{ 
    position: absolute; right: 6px; top: 50%; margin-top:-10px;
  }
  .w3-boxmodel .margin .mb, .w3-boxmodel .margin .pb, .w3-boxmodel .margin .bb{ 
    position: absolute; bottom: 10px; left: 50%; margin-left:-10px;
  }      

  .w3-boxmodel .margin:before {
    content: "margin";
    position: absolute;
    left: 0;
    top: 3%;
    width: 100%;
    padding-left: 12px;
    text-align: left;
  }
  .w3-boxmodel .border {
    padding: 45px;
    position: relative;
    background: #98D4D5;
  }
  .w3-boxmodel .border:before {
    content: "border";
    color: #333;
    position: absolute;
    left: 0;
    top: 5%;
    width: 100%;
    padding-left: 12px;
    text-align: left;
  }
  .w3-boxmodel .padding {
    color:black;
    padding: 45px;

    position: relative;
    background: #f1f1f1;
  }
  

  .w3-boxmodel .padding:before {
    content: "padding";
    width: 100%;
    position: absolute;
    left: 2%;
    top:7%;
    padding-left: 12px;
    text-align: left;
  }
  .w3-boxmodel .content {
    padding: 20px;

    position: relative;
    background: white;
    border: 2px dashed #bbb;
  }
  .w3-boxmodel .content:before {
    content: "CONTENT";
    display: block;
    text-align: center;
    line-height: 3.5;
    width: 100%;
  }


  div.dropdown__control{
    border-radius: 1px;
    background: #ffffff;
    color: #13364F;
    font-size: 14px;
    border: 1px solid #D9DFE3;
    border-radius: 4px;
    min-height:32px !important;
  } 
  

  .dropdown__value-container{
    background: transparent;
    height: 32px;
  }

  .dropdown__dropdown-indicator {
    padding:4px 2px !important;
  }

  .dropdown__dropdown-indicator svg {
    color: #13364F !important;
    opacity: 0.4;
  }

  span.dropdown__indicator-separator {
    color: #13364F !important;
    background: transparent;
    margin-top:0;
    margin-bottom: 0;
  }

  .dropdown__indicators {
    background-color: transparent;
    border: none !important;

  }

  .dropdown__menu {
      z-index: 99999;
  }
  
  .dropdown--is-disabled{  
    opacity: 0.5; 
  }

  .dropdown__control.dropdown__control--is-disabled{
    background: #bdbdbd;
  }
  
 .revui_input_text {
    background: #F1F1F1;
    display: block;
    padding: 8px;
    padding: 8px 16px 8px 0px !important;

 }

  input {
      border: 0;
      margin: 0;
      border-radius: 1px;
      background-color: inherit;
      color: #13364F;
      width: 100%;
      font-size: 14px;
      height: 32px;
  }


  .MuiCard-root{
    overflow: visible;
  }


  .grid-editor{
    .MuiFormLabel-root,
    .MuiInputBase-input{
      min-width:50px;
    }
    .MuiGrid-container{
      flex-wrap:inherit;
    }



  }



.MuiInputLabel-shrink{
  transform: translate(0, -3px) scale(1) !important;
  transform-origin: top left;
}

.MuiTypography-root.MuiFormControlLabel-label{
  color: #13364F;
  font-size: 14px;
  font-weight: 800;
}

// Spacing Field Styles

#spacingContainer{

  .spacing-radio {
    margin-top:20px;
    margin-right:10px;
    float:left;
    border: 1px solid #efefef;
    cursor:pointer;
    h3{
      font-size:0.8rem;
      color:#13364F;
      margin: 0 0 20px;
    }
    background-color:#f8f8f8;
    width:100px;
    height:100px;
    padding:10px;
    .spacing-input{
      display:none;
    }

    .gfx-block{
      width:100%;
      height:15px;
      display:block;
      background-color:#98D4D5;
    }
    &.selected{
      border: 1px solid #98D4D5;
      background-color:#98D4D5;
      h3{
        color:#E9FBFB;
      }
      .gfx-block{
        background-color:#D1ECEC;
      }  
    }
    &.compact-graphic{
      .gfx-block{
          margin-bottom:3px;
      }
    }
    &.normal-graphic{
       .gfx-block{
        margin-bottom:6px;
      }
    }
    &.super-graphic{
       .gfx-block{
        margin-bottom:10px;
      }
    }
  }
}

// Header Layout Field Styles

#HeaderLayoutContainer{
  .layout-radio {
    margin-top:20px;
    margin-right:10px;
    float:left;
    border: 2px dashed $mediumGrey;
    cursor:pointer;
    width:100%;
    padding:10px;
    marign-bottom:10px;
    background-color: $lightGrey;
    .logo{
      font-size:0.8rem;
      color:white;
      margin: 0 0 20px;
      padding:10px;
      width:15%;
      background:$primaryColor;
      display:block;
      font-weight: bold;
    }
  
    .layout-input{
      display:none;
    }

    .gfx-block{
      width:50%;
      height:15px;
      display:block;
      background-color: $medLightGrey;
      margin-bottom:7px;
    }
    .gfx-block-long{
      width:70%;
    }
    

    &.center-graphic{
        .logo{
          margin:0 auto 10px;
        }
        .gfx-block{
          margin:0 auto 6px;
        }
    }
    &.left-graphic{
        .logo{
          float:left;
          margin-bottom:0px;
        }
        .gfx-block{
          float:right;
        }
    }
    &.right-graphic{
      .logo{
        float:right;
        margin-bottom:0px;
      }
      .gfx-block{
        float:left;
      }
      
    }
    &.selected{
      border: 2px solid  $primaryColor;
      background-color:$primaryColor;
      .logo{
        color:$primaryColor; 
        background: $primaryColorLight;
      }
      .gfx-block{
        background-color:$primaryColorLight;
      }  
    }
  }
}


// Feature Layout Field Styles

#FeatureLayoutContainer{
  .layout-radio {
    margin-top:20px;
    margin-right:10px;
    float:left;
    border: 2px dashed $mediumGrey;
    cursor:pointer;
    width:100px;
    height:100px;
    padding:10px;
    marign-bottom:10px;
    background-color: $lightGrey;
    .logo{
      font-size:0.8rem;
      color:white;
      margin: 0 auto 10px;
      width:40px;
      padding:10px 20px 10px;
      background:$primaryColor;
      display:block;
      font-weight: bold;
      text-align:center;
    }
  
    .layout-input{
      display:none;
    }

    .gfx-block{
      width:50%;
      height:15px;
      display:block;
      background-color: $medLightGrey;
      margin:0 auto 10px;
      text-align:center;
      line-height:14px;
      padding:2px;
      font-size:12px;
      color:$primaryColorDark;
    }
    .gfx-btn{
      border-radius:2px;
      height:15px;
      display:block;
      width:60px;
      margin:0 auto 5px;
      text-align:center;
      line-height:13px;
      padding:3px 5px;
      font-size:11px;
      background-color: $primaryColorDark;
      color:white;
      margin-top:10px;
    }

    .gfx-text{
      font-size: 11px;
      text-align: center;
      margin-top: 10px;
      color:$primaryColorDark;
    }

    .gfx-block-long{
      width:70%;
    }
    

    &.layout-one-graphic{

    }
    &.layout-two-graphic{

    }
    &.layout-three-graphic{
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: repeating-linear-gradient(
        45deg,
        $lightGrey,
        $lightGrey 10px,
        $primaryColorLighter 10px,
        $primaryColorLighter 20px
      );
    }
    &.selected{
      border: 2px solid  $primaryColor;
      background:$primaryColor;
      .gfx-text{
        color:white;
      }
      .logo{
        color:$primaryColor; 
        background: $primaryColorLight;
      }
      .gfx-block{
        background-color:$primaryColorLight;
      }  
    }
  }
}


#RoundelContainer{
  .layout-radio {
    position:relative;
    margin-bottom:20px;
    margin-right:10px;
    float:left;
    border: 2px dashed $mediumGrey;
    cursor:pointer;
    width:45px;
    height:45px;
    padding:10px;
    marign-bottom:10px;
    background-color: $lightGrey;

    .layout-input{
      display:none;
    }

    .gfx-block-long{
      width:70%;
    }

    .gfx-circle{
        width:45px;
        height:45px;
        background: $primaryColor;
        border-radius:30px;
        display:block;
        margin-left: auto;
        margin-right:auto;
    }
    .gfx-square{
      width:45px;
      height:45px;
      background: $primaryColor;
      display:block;
      margin-left: auto;
      margin-right:auto;
    }
    .gfx-triangle{
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 45px 0 0 45px; 
      border-color: transparent transparent transparent $primaryColor;
      display:block;
      margin-left: auto;
      margin-right:auto;
    }

    .gfx-quarter-circle{
      width:45px;
      height:45px;
      background: $primaryColor;
      display:block;
      margin-left: auto;
      margin-right:auto;
      border-radius:0px 60px 0px 0px;
    }

    .gfx-banner{
      width: 15px;
      height: 45px;
      background: #98D4D5;
      display: block;
      margin-left: auto;
      margin-right:auto;
    } 

    .gfx-layout{
      position: absolute;
      width:30px;
      height:30px;
      background:$primaryColor;
    }

    .shape-quarter-circle{
      border-radius: 0px 30px 0px 0px;
    }

     .shape-circle.gfx-layout{
        border-radius: 30px;
      }

      .shape-banner.gfx-layout{
        width:10px;
      }
    

    .shape-triangle.gfx-layout{
      width: 0;
      height: 0;
      border-style: solid;
      &.gfx-bottom-left{
        border-color: transparent transparent transparent $primaryColor;
        border-width: 30px 0 0 30px;
      }
      &.gfx-bottom-right{
        border-color: transparent transparent $primaryColor transparent;
        border-width: 0 0 30px 30px;
      }
      &.gfx-top-left{
        border-color: $primaryColor transparent transparent transparent;
        border-width: 30px 30px 0 0;
      }
      &.gfx-top-right{
        border-color: transparent $primaryColor transparent transparent;
        border-width: 0 30px 30px 0;
      }
      background:none;
    }

    .gfx-layout.shape-quarter-circle{

      &.gfx-bottom-left{
        border-radius: 0 100% 0px 0px;
      }
      &.gfx-bottom-right{
        border-radius: 100% 0px 0px 0px;
      }
      &.gfx-top-left{
        border-radius: 0px 0px 100% 0px;
      }
      &.gfx-top-right{
        border-radius: 0px 0px  0px 100%;
      }
    }

    .gfx-top-left{
      top:5px;
      left:5px;
    }
    .gfx-top-right{
      top:5px;
      right:5px;
    }
    .gfx-bottom-left{
      bottom:5px;
      left:5px;
    }
    .gfx-bottom-right{
      bottom:5px;
      right:5px;
    }


    &.selected{
      border: 2px solid  $primaryColor;
      background:$primaryColor;
      .gfx-shape, .gfx-layout{
        background:white;
      }
      .gfx-triangle{
        border-color: transparent transparent transparent white;
      }
      .gfx-quarter-circle{
        background:white;
      } 
      .gfx-layout.shape-triangle{
        background:none;
        &.gfx-bottom-left{
          border-color: transparent transparent transparent white;
        }
        &.gfx-bottom-right{
          border-color: transparent transparent white transparent;
        }
        &.gfx-top-left{
          border-color: white transparent transparent transparent;
        }
        &.gfx-top-right{
          border-color: transparent white transparent transparent;
        }
      }
    }
  }
}

@keyframes fadeScale {
  0% {  
    opacity:0;
  }
  100% {   
    opacity:1;
  }
}

.field-label {
  opacity: 0.7;
}

.label-with-tooltip{
  display: inline-block;
  margin: 0px;
  color: #13364F;
  margin-right: 8px;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 13px;
}
.tooltip-contain{
  display: block;
  flex-direction: row !important;
  position: relative;
  margin-bottom: 0px !important;
}
.i-tooltip{  
  display: inline-block;
  border-radius: 13px;
  width: 14px;
  height: 14px;
  border: 1px solid #8899A5;
  font-size: 12px;  
  line-height: 11px;
  text-align: center;
  font-weight: 900;
  color: #8899A5;
  cursor: pointer;
  &:before {
    content: attr(data-text); 
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    position:absolute;
    bottom:0px;
    transform:translateY(100%);
    right:0px;
    margin-left:8px;
    width: 100%;
    padding:10px;
    border-radius:10px;
    background:#425E72;
    color: #fff;
    text-align:center;
    display:none; 
    opacity: 0;
    z-index: 999;
  }
  &:hover:before {
    display:block;
    animation: fadeScale 0.3s forwards;
  }
}

.MuiCheckbox-root .MuiSvgIcon-root{
  border-color: #D9DFE3;
}

.schema-editor{

  max-width: 620px;
  padding-top: 32px !important;

  .MuiGrid-root .MuiCardContent-root{
    padding-left: 0px;
    position:relative;
  }

  .MuiSwitch-root{
    margin-left: 40px;
  }

  div[class^="SchemaAttachedEditor-menuItem"]{
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;  
  }

  .current-menu__title{
    font-size: 24px !important;
    margin-top: 32px;
  }

  .title-group{
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #DFF1F1;
  }

  .MuiTypography-h5, .title-group__title{
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;  
    margin-bottom: 32px;
  }

  .collapse-toggle {
    .plus {
      display: none;
    }
    .minus {
      display: inline;
    }
    &.collapsed{
      .plus {
        display: inline;
      }
      .minus {
        display: none;
      }
    }
  }
}


.accordion, .accordion-item, .accordion-header, .accordion-button{
  background: transparent !important;
}
